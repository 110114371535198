import Logo from '../images/logo.png'
import Emmanuel from '../images/Babawale-Emmanuel-Salako.jpg'
import James from '../images/Olanipekun-Fabunmi-James.jpeg'
import Michael from '../images/Michael Owoseni.jpg'
import partner1 from '../images/Nipss.jpeg'
import partner2 from '../images/comeg.jpeg'
import partner3 from '../images/partner.jpeg'
import VAM from '../images/mission.jpg';
import MAV from '../images/vision.jpg'
import DGB from '../images/mission.jpg'


const Section2 = () => {
   return (
      <>
         <div className='w-full py-12 md:py-20 lg:py-20 xl:py-20 2xl:py-20 pb-32'>
            <div className='w-full px-4 md:px-16 lg:px-20 xl:px-28 2xl:px-40'>
               <h1 className='text-[#007e00] font-regular font-sans text-xl md:text-xl lg:text-xl xl:text-xl pb-10 md:pb-0 lg:pb-0 xl:pb-0'>Get Involved</h1>
            </div>
            <div className='w-12/12 flex flex-wrap-reverse justify-center'>
               <div className='w-12/12 md:w-5/12 lg:w-5/12 xl:5/12 px-4 md:px-0 lg:px-0 xl:px-0'>
                  <div className='w-12/12 md:9/12 lg:9/12 xl:9/12 pt-5 md:pt-20 lg:pt-20 xl:pt-28 2xl:pt-40'>
                     <h1 className='text-black font-bold font-professional text-2xl md:text-3xl lg:text-2xl xl:text-4xl 2xl:text-5xl'>Empower Earth Sciences</h1>
                     <h1 className='text-black font-sans text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl pb-10 pt-4'>
                        Your involvement fuels progress. Join us in cultivating knowledge, nurturing growth, and embracing sustainability in Earth sciences.
                     </h1>
                  </div>
                  <div className='flex gap-4'>
                     <button className='text-white p-2 px-4 rounded-lg bg-[#007e00]'>Get Involved</button>
                  </div>
               </div>
               <div className='w-12/12 md:w-5/12 lg:5/12 xl:5/12 xl:5/12 px-4 md:px-0 lg:px-0 xl:px-0 pt-0 md:pt-20 lg:pt-0 xl:pt-0 2xl:pt-0'>
                  <img src={DGB} className='rounded-lg w-12/12' />
               </div>
            </div>
         </div>
      </>
   )
}

export default Section2
//Third section
