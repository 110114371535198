import { Fragment } from 'react'


const FooterSection = () => {
  return (
    <>
      <div className="bg-lightergray">
        <div className='h-24 w-full flex justify-evenly pt-1'>
          <div className='p-10'>
            <h1 className='text-black text-sm font-professional pb-8 md:text-base'>Copyright 2023-Gesina International All rights reserved | Website by Heuvera Developers</h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterSection
//The footer section

