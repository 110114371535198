import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import {
  MenuIcon,
  XIcon,
  ChevronRightIcon,
  LoginIcon,
} from '@heroicons/react/solid'
import Logo from '../images/Mainpage logo.png'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const BlogNavigation = () => {
  return (
    <>
      <Popover className=" bg-white mt-1 mb-2   sticky top-0 z-50">
        <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="example">
              <span className="sr-only">Gesina</span>
              <div className='pl-0 md:pl-16 lg:pl-16 xl:pl-16'>
                <img className="w-72" src={Logo} alt="" /> 
              </div>
              
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </Popover.Button>
       </div>
      <Popover.Group as="nav" className="hidden md:flex space-x-10 pr-28">
            <Link
              to="/"
              href="example"
              className="text-base font-professional text-black  hover:text-[#60a5fa] hover:duration-500"
            >
              HOME
            </Link>
            <Link
              to="/About-us"
              className="text-base font-professional text-black hover:text-[#60a5fa] hover:duration-500"
            >
              ABOUT
            </Link>
            <Link
              to="/Services"
              className="text-base font-professional text-black  hover:text-[#60a5fa] hover:duration-500"
            >
              SERVICES
            </Link>
            <Link
              to="/Blog"
              className="text-base font-professional text-[#60a5fa]  hover:text-[#60a5fa] hover:duration-500"
            >
              BLOG
            </Link>
            <Link
              to="/Contact-us"
              className="text-base font-professional text-black  hover:text-[#60a5fa] hover:duration-500"
            >
              CONTACT
            </Link>
          </Popover.Group>  
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-lightergray ring-opacity-5 bg-white divide-y-2 divide-lightergray">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <Link to="/">
                      {/* <img className="h-8 w-auto" src={Logo} alt="Workflow" /> */}
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-10">
                <div className="grid grid-rows gap-7">
                  <div className="py-5 pl-4 outline-lightergray outline outline-1 oultine-0 rounded-full shadow-sm flex justify-between">
                    <Link
                      to="/"
                      className="text-base font-professional text-black  hover:text-[#60a5fa] hover:duration-500"
                    >
                      HOME
                    </Link>
                    <a>
                      <ChevronRightIcon className="mr-2 -mr- w-5 h-4 mt-1" />
                    </a>
                  </div>
                  <div className="py-5 pl-4 outline-lightergray outline outline-1 oultine-0 rounded-full shadow-sm flex justify-between">
                    <Link
                      to="/About-us"
                      className="text-base font-professional text-black hover:text-[#60a5fa] hover:duration-500"
                    >
                      ABOUT
                    </Link>
                    <a>
                      <ChevronRightIcon className="mr-2 -mr- w-5 h-4 mt-1" />
                    </a>
                  </div>

                  <div className="py-5 pl-4 outline-lightergray outline outline-1 oultine-0 rounded-full shadow-sm flex justify-between">
                    <Link
                      to="/Services"
                      className="cursor-pointer text-base font-professional text-black  hover:text-[#60a5fa] hover:duration-500"
                    >
                      SERVICES
                    </Link>
                    <a>
                      <ChevronRightIcon className="mr-2 -mr- w-5 h-4 mt-1" />
                    </a>
                  </div>

                  <div className="py-5 pl-4 outline-lightergray outline outline-1 oultine-0 rounded-full shadow-sm flex justify-between">
                    <a
                      href="/Blog"
                      className="text-base font-professional text-[#60a5fa]  hover:text-[#60a5fa] hover:duration-500"
                    >
                      BLOG
                    </a>
                    <a>
                      <ChevronRightIcon className="mr-2 -mr- w-5 h-4 mt-1" />
                    </a>
                  </div>
                  <div className="py-5 pl-4 outline-lightergray outline outline-1 oultine-0 rounded-full shadow-sm flex justify-between">
                    <Link
                      to="/Contact-us"
                      className="text-base font-professional text-black  hover:text-[#60a5fa] hover:duration-500"
                    >
                      CONTACT
                    </Link>
                    <a>
                      <ChevronRightIcon className="mr-2 -mr- w-5 h-4 mt-1" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  )
}

export default BlogNavigation
