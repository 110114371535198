import { Fragment } from 'react'
import { RiArrowDropRightLine } from 'react-icons/ri'
import Training1 from '../images/Training.jpg'
import Biz from '../images/Biz.jpg'
import audit from '../images/audit.jpg'
import partner1 from '../images/Nipss.jpeg'
import partner2 from '../images/comeg.jpeg'
import partner3 from '../images/partner.jpeg'
const BlogmainSection = () => {
  return (
    <>
      <div className="w-full 2xl:px-44 xl:px-4 lg:px-4 md:px-44 px-4 py-40">
      <h1 className='text-black font-regular text-center font-sans text-2xl pt-8 pl-4 md:text-3xl lg:text-3xl xl:text-3xl'>Our Partners</h1>
        <h1 className='text-darkergray font-standard pb-8 text-base md:text-xl lg:text-base xl:text-xl 2xl:text-xl text-center'>Collaborating for Success: Meet Our Trusted Partners</h1>
        <div className="w-full flex justify-center mx-8 flex-wrap gap-4 md:gap-10 lg:gap-10 xl:gap-10 pb-10 md:pb-20 pt-5 md:pt-10 lg:pt-16 xl:pt-20">
          <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="w-80 md:w-96 lg:w-80 xl:w-96 rounded-2xl flex flex-col justify-center items-center ">
              <img src={partner1} className="w-40 h-36 md:w-72 md:h-48 lg:w-40 lg:h-44 xl:w-40 xl:h-56" alt="Partner 1" />
              <p className="m-2 text-center">The National Institute for Policy and Strategic Studies</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="w-80 md:w-96 lg:w-80 xl:w-96 rounded-2xl flex flex-col justify-center items-center ">
              <img src={partner2} className="w-52 h-36 md:w-72 md:h-48 lg:w-64 lg:h-44 xl:w-68 xl:h-56" alt="Partner 2" />
              <p className="m-2 text-center">The Council of Nigeria Mining Engineers and Geoscientists</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
            <div className="w-80 md:w-96 lg:w-80 xl:w-96 rounded-2xl flex flex-col justify-center items-center ">
              <img src={partner3} className="w-52 h-36 md:w-72 md:h-48 lg:w-64 lg:h-44 xl:w-72 xl:h-56" alt="Partner 3" />
              <p className="m-2 text-center">Nigerian Mining and Geosciences Society (NMGS)</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogmainSection
//Third section
