import { Fragment } from 'react'
import FooterSection from '../footer/footer'
import Navigation from '../navigation_bar/navigation_bar'
import AboutNavigation from './aboutNavigation'
import Section2 from './aboutsection'
import Section1 from './about_us'
import AboutHero from '../hero/aboutHero'



const About = () => {
  return (
    <>
    <AboutNavigation/>
    <AboutHero/>
    <Section2/>
    <FooterSection/>
    </>
  )
}

export default About
//Home page
