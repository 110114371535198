import { Fragment } from 'react';
import Image from '../images/study.jpg';
import GeoImage from '../images/Geoimage1.jpg';
import Community from '../images/community.jpg';
import Initiative from '../images/initative.jpg';
import Research from '../images/researrch.jpg'; // Corrected the image file name
import { BsArrowRightCircle } from 'react-icons/bs';
import { IoCompassOutline } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { FaRegClock } from "react-icons/fa";
import { IoLink } from "react-icons/io5";
import VAM from '../images/uni.jpg';

const Section = () => {
  return (
    <div className="w-12/12">
      <div className="bg-hero2 p-4 md:p-16 lg:p-16 xl:p-16 py-20 md:py-20 lg:py-20 xl:py-20 2xl:py-20 ">
        <h1 className="text-white text-2xl md:text-3xl lg:text-3xl xl:text-3xl text-center font-sans">About Us</h1>
        <div className='flex flex-wrap justify-center w-12/12 '>
          <div className="w-full flex flex-wrap gap-10 justify-between md:justify-center lg:justify-center xl:justify-center 2xl:justify-center my-10">
            <div className='bg-white w-12/12 md:w-72 lg:w-80 xl:w-80 h-80 md:w-72 lg:h-80 xl:h-80 rounded-lg  flex flex-col justify-center items-center'>
              <IoCompassOutline className='text-5xl text-[#007e00]' />
              <h1 className='text-black font-bold font-sans text-2xl text-center mt-4'>Mission</h1>
              <h2 className='text-[#808080] font-sans text-base text-center px-4 mt-2'>
                Igniting Earth science passion, cultivating innovative thinking in Geosciences, GESINA sparks renewed interest across Nigeria and Africa for transformative impact.
              </h2>
            </div>
            <div className='bg-white w-12/12 md:w-72 lg:w-80 xl:w-80 h-80 md:w-72 lg:h-80 xl:h-80 rounded-lg  flex flex-col justify-center items-center'>
              <FaRegEye className='text-5xl text-[#007e00]' />
              <h1 className='text-black font-bold font-sans text-2xl text-center mt-4'>Vision</h1>
              <h2 className='text-[#808080] font-sans text-base text-center px-4 mt-2'>
                GESINA envisions leading Earth sciences, inspiring knowledge, and fostering transformative global impact, creating resilient communities and environmental harmony.
              </h2>
            </div>
            <div className='bg-white w-12/12 md:w-72 lg:w-80 xl:w-80 h-80 md:w-72 lg:h-80 xl:h-80 rounded-lg  flex flex-col justify-center items-center'>
              <GiReceiveMoney className='text-5xl text-[#007e00]' />
              <h1 className='text-black font-bold font-sans text-2xl text-center mt-4'>Support</h1>
              <h2 className='text-[#808080] font-sans text-base text-center px-4 mt-2'>
                GESINA seeks transformative support and donations, catalyzing Earth sciences, education, and sustainability, fostering innovation, and global impact through philanthropy.
              </h2>
            </div>
            <div className='bg-white w-12/12 md:w-72 lg:w-80 xl:w-80 h-80 md:w-72 lg:h-80 xl:h-80 rounded-lg  flex flex-col justify-center items-center'>
              <FaRegHandshake className='text-5xl text-[#007e00]' />
              <h1 className='text-black font-bold font-sans text-2xl text-center mt-4'>Partners</h1>
              <h2 className='text-[#808080] font-sans text-base text-center px-4 mt-2'>
                GESINA seeks transformative collaborations, uniting with global partners to advance Earth sciences, foster innovation, and promote sustainable solutions.
              </h2>
            </div>
            <div className='bg-white w-12/12 md:w-72 lg:w-80 xl:w-80 h-80 md:w-72 lg:h-80 xl:h-80 rounded-lg  flex flex-col justify-center items-center'>
              <IoLink className='text-5xl text-[#007e00]' />
              <h1 className='text-black font-bold font-sans text-2xl text-center mt-4'>Eco-Synchronicity</h1>
              <h2 className='text-[#808080] font-sans text-base text-center px-4 mt-2'>
                The Geoscience Sustainability Clock symbolizes interconnectedness in sustainability, emphasizing the time-sensitive nature of addressing global challenges.
              </h2>
            </div>

          </div>
        </div>
        <div className='w-12/12 bg-white rounded-lg p-4 md:p-8 lg:p-8 xl:p-8'>
          <div className='w-full flex flex-wrap gap-2 justify-between items-center mb-8'>
            <h1 className='text-black font-sans text-3xl md:text-2xl lg:text-3xl xl:text-3xl'>About Gesina</h1>
            <div className='flex flex-wrap justify-between w-96 md:w-80 lg:w-96 xl:w-96 2xl:w-96'>
              <h1 className='text-black font-sans font-bold text-base'>Innovative</h1>
              <h1 className='text-black font-sans font-bold text-base'>Passionate</h1>
              <h1 className='text-black font-sans font-bold text-base'>Transformative</h1>
            </div>
          </div>
          <div className='w-full flex flex-wrap-reverse md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center gap-12'>
            <div className='w-12/12 md:w-6/12 lg:w-6/12 xl:w-6/12'>
              <h2 className='font-sans font-semibold text-2xl'>Grow Earth Science in Nigeria</h2>
              <p className='pt-2 mt-0 font-professional text-black sm:text-sm md:text-sm lg:text-sm xl:text-base'>
                GESINA International is aa visionary initiative sculpting the future of Earth sciences across the landscapes of Nigeria and Africa. At GESINA, we transcend conventional boundaries, encapsulating innovation, passion, and transformative vision in every endeavor. Our commitment extends beyond the ordinary, as we endeavor to foster a culture of profound thinking in Geosciences, propelling a renaissance of curiosity and excellence.
              </p>
              <p className='pt-4 mt-0 font-professional text-black sm:text-sm md:text-sm lg:text-sm xl:text-base'>
                In the intricate tapestry of GESINA's mission, the threads of knowledge intertwine seamlessly with the fabric of growth. We aspire not only to reignite a passion for Earth sciences but to be architects of change, paving the way for sustainable progress. Through pioneering initiatives that push the boundaries of exploration, GESINA becomes a living testament to environmental stewardship, advocating for responsible resource management.
              </p>
              <p className='pt-4 mt-0 font-professional text-black sm:text-sm md:text-sm lg:text-sm xl:text-base'>
                Our journey unfolds with collaborative endeavors, establishing bridges with universities, research institutions, and governmental bodies. GESINA's initiatives ripple through communities, making Earth sciences accessible to diverse demographics. As we champion inclusivity, our collective impact resonates across boundaries, influencing a collective consciousness for a harmonious coexistence with our planet.
              </p>
              <p className='pt-4 mt-0 font-professional text-black sm:text-sm md:text-sm lg:text-sm xl:text-base'>
                GESINA strives to be more than an initiative; it is a transformative force weaving its narrative into the very fabric of Earth sciences. We envisage a future where the echoes of our commitment reverberate, leaving an indelible mark on the geoscientific landscape. With every stride, we propel Nigeria and Africa towards a horizon where Earth sciences become the cornerstone of societal growth, ushering in an era of wisdom, sustainability, and enduring progress.
              </p>
            </div>
            <div className='w-12/12 md:w-6/12 lg:w-6/12 xl:w-6/12'>
              <img className='rounded-lg' src={VAM} alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
