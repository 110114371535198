import { Fragment } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { BsTelephone } from 'react-icons/bs';

const ProgramHero = () => {
  return (
    <>
    <div className="bg-white">
<div className="flex items-center">
  <div className="bg-hero3 bg-cover py-20 sm:py-40 w-full">
    <h1 className="text-white font-standard text-3xl sm:text-5xl font-semibold ml-4 md:ml-8 lg:ml-10 xl:ml-14 2xl:ml-40">
    Programs and Resources
    </h1>
    <h2 className="ml-4 mt-2 sm:ml-40 text-white font-standard text-base sm:text-xl">
    Dive into our wealth of educational programs and resources, connecting
  <br></br> you with the wonders of Earth sciences and the tools for environmental change.
    </h2>
  </div>
</div>
</div>


    </>
  );
};
export default ProgramHero;
