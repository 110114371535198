import Consulting from '../images/Consultancy Image.jpg'
import Accountancy from '../images/Services.jpg'
import Management from '../images/Consulting.jpg'
import Revenue from '../images/Accountancy.jpg'
import Consult from '../images/Consult.png'
import Training from '../images/Training.jpeg'
import Training1 from '../images/Training.jpg'
import Biz from '../images/Biz.jpg'
import audit from '../images/audit.jpg'
import Image from '../images/study.jpg'
import GeoImage from '../images/Geoimage1.jpg'
import Community from '../images/community.jpg'
import Initiative from '../images/initative.jpg'
import Research from '../images/researrch.jpg'


const ServiceSection1 = () => {
  return (
    <>
      <div className='w-full p-4 md:p-16 lg:p-16 xl:p-16 2xl:p-16'>
      <h1 className='text-black font-regular font-sans text-center text-2xl pt-8 pl-4 md:text-3xl lg:text-3xl xl:text-3xl p-16'>Our Gallery</h1>
        <div className='flex flex-wrap gap-10 justify-center'>
          <div className='w-96 h-96 bg-black'>
            <img src={Research} className='w-full h-96' />
          </div>
          <div className='w-96 h-96 bg-black'>
            <img src={Research} className='w-full h-96' />
          </div>
          <div className='w-96 h-96 bg-black'>
            <img src={Research} className='w-full h-96' />
          </div>
          <div className='w-96 h-96 bg-black'>
            <img src={Research} className='w-full h-96' />
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceSection1
//Third section
