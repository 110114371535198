import { Fragment } from 'react'
import FooterSection from '../footer/footer'
import Navigation from '../navigation_bar/navigation_bar'
import ServiceSection1 from './our_section'
import ServiceSection from './our_services_section'
import ServicesNavigation from './servicesNavigation'
import ProgramHero from '../hero/programHero'



const Ourservices = () => {
  return (
    <>
    <ServicesNavigation/>
    <ProgramHero/>
    {/* <ServiceSection/> */}
    <ServiceSection1/>
    <FooterSection/>
    </>
  )
}

export default Ourservices
//Home page
