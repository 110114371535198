import BlogNavigation from "../blogNavigation"
import {RiArrowDropRightLine} from 'react-icons/ri'
import audit from '../BlogSection/audit.jpg'

const SecondBlog = () => {
    return (
      <>
      <BlogNavigation/>
      <div className="bg-lightergray w-full h-28 flex justify-between xl:px-28 lg:px-44 md:px-44 px-4">
        <h1 className='font-professional text-black text-2xl font-semibold pt-10'>FINANCIAL STATEMENTS</h1>
        <div className='pt-12'>
        <h1 className='flex font-professional text-black text-sm'>Blog<RiArrowDropRightLine className='h-5 w-5'></RiArrowDropRightLine>FINANCIAL STATEMENTS</h1>
     </div>
     </div>
     <div className="bg-white w-full flex-wrap xl:flex-nowrap flex gap-5 p-4">
      <div className="w-full">
      <div className="w-full flex xl:flex-nowrap lg:flex-nowrap 2xl:flex-nowrap flex-wrap gap-5">
      <div className="bg-[#fbf6f2] w-12/12 md:lg:w-8/12 xl:w-8/12 2xl:w-8/12 rounded-2xl">
      <img src={audit} className="w-full rounded-3xl p-4"/>
        <h1 className="mx-4 mb-4 mt-4 text-black font-bold text-2xl font-standard">Financial Statements</h1>
        <h1 className="mx-4 text- text-base">Financial statements are summarize formal records that present the financial activities of a business. They provide essential information to stakeholders, including investors, lenders, employees, and government agencies, enabling them to assess the financial performance and position of a company. 
 
</h1>

      </div>
      <div className="bg-[#edf4fb] w-12/12 md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12 rounded-2xl">
      <img src={audit} className="w-full h-96 rounded-3xl p-4"/>
        <h1 className="mx-4 mb-4 mt-4 text-black font-bold text-2xl font-standard">Responsible Collecting Bodies</h1>
        <h1 className="mx-4 text-base mb-4">      1. Record Transactions:
Financial statements are prepared based on accurate and complete accounting records. Transactions are recorded in journals and then transferred to general ledgers, then to trial balance and finally used to populate the component of financial statements. Proper bookkeeping practices, including recording revenues, expenses, assets, and liabilities, are crucial for generating reliable financial statements.</h1>
<h1 className="mx-4 mb-4 text-base">
2. Adjustments and Closing Entries:
To ensure accuracy, adjusting entries are made to account for items like accrued revenue, prepaid expenses, and depreciation. Closing entries are performed at the end of an accounting period to transfer temporary accounts (revenue, expenses, and dividends) to retained earnings
</h1>
      </div>
      </div>
      <div className="w-12/12 bg-[#edf4fb] mx-2 pb-2 flex xl:flex-nowrap lg:flex-nowrap 2xl:flex-nowrap flex-wrap mt-5 rounded-2xl">
      <img src={audit} className="w-12/12 md:w-6/12 lg:w-6/12 xl:w-6/12 2xl:w-6/12 rounded-3xl p-4"/>
      <div className="">
      <h1 className="mx-4 mt-4 text-black font-bold text-2xl font-standard">Components of Financial Statements</h1>
      <h1 className="mx-4 text-base mt-4">
      1. Statement of Comprehensive Income (or Profit and Loss Statement):
This statement presents a business’s revenue/turnover, direct cost, expenses, and net income (or loss) over an accounting period. It shows the company's ability to generate profit by comparing revenues earned against expenses incurred.
      </h1>
      <h1 className="mx-4 text-base mt-4">
      2. Statement of Financial Position:
This statement provides an overview of a company's financial position at a specific point in time. It summarizes its assets, liabilities, and equity. The balance sheet equation states that assets equal liabilities plus equity. 
      </h1>
      <h1 className="mx-4 text-base mt-4">
      3. Statement of Cash Flow:
The cash flow statement details the inflow and outflow of cash during a particular period. It categorizes cash flows into three main sections: operating activities, investing activities, and financing activities. Operating activities include cash generated from day-to-day operations while investing activities encompass the buying or selling of assets. Financing activities involve cash flows from debt, equity, or dividend transactions.
      </h1>
      <h1 className="mx-4 text-base mt-4">
      4. Statement of Changes in Equity
 This statement provides a summary of the changes in a company's shareholders' equity over a specific period. It complements the balance sheet by explaining the reasons behind the variations in equity. This statement helps stakeholders understand the sources and uses of funds that impact a company's ownership interest. 
      </h1>
      <h1 className="mx-4 text-base mt-4">
      5. Notes to Financial Statements:
These are additional disclosures and explanations that accompany the financial statements. They provide crucial details about accounting policies, assumptions, contingencies, and other relevant information. 
      </h1>

      </div>
      </div>
      </div>
      {/* D */}
      {/* Types of Taxes */}
     <div className="w-12/12 md:w-4/12 lg:w-full xl:w-4/12 2xl:w-4/12 bg-[#fbf6f2] rounded-2xl">
     <img src={audit} className="h-64 md:lg:xl:2xl: w-full rounded-3xl p-4"/>
      <h1 className="mx-4 mb-4 mt-4 text-black font-bold text-2xl font-standard">Uses of Financial Statements</h1>
      <h1 className="mx-4 text-base mb-4">       1. Bridging the Gap in Management
Financial statements basically reflect a company’s financial performance. They show the profits and liabilities of the business. They show how successful a company’s decisions have been. Since shareholders have access to these statements, they can gauge their company’s performance. This further helps in bridging the gap between lapses in management and the expectations of owners. </h1>
      <h1 className="mx-4 text-base mb-4">Availing of Credit from Lenders
Every business needs to borrow funds for functioning. They have to rely on lenders like banks and financial institutions for this purpose. Financial statements play a huge role in this purpose. Since they show a company’s liabilities, debts, and profits, investors can use them to make informed decisions.</h1>
      <h1 className="mx-4 text-base mb-4">     Use for Investors
Investors also extensively use a company’s financial statements to assess its finances. That helps them figure out how the company’s solvency will be in the longer term. </h1>
      <h1 className="mx-4 text-base mb-4">     4.  Use for Government
Governmental policies pertaining to corporates depend heavily on financial statements. This is because these statements depict how companies are functioning in general. The government can use this information to decide taxation and regulatory policies.</h1>
      <h1 className="mx-4 text-base mb-4">    5.  Use for Stock Exchanges
Regulatory bodies use financial statements for many reasons. They can assess a company’s internal matters using them to ensure the protection of investors. Even stock advisers require them to frame their quotes. They are also a great source of information for stock traders and investors.</h1>
      <h1 className="mx-4 text-base mb-4">
      6.  Information on Investments
The shareholders of a company rely on these statements to understand how their investments are paying off. If a company is earning profits, they might decide to invest even more money. On the contrary, stagnant profits or even losses will prompt them to pull out. Despite all these uses of financial statements, there are some limitations to them as well.
      </h1>

     </div>
      
    </div>


      </>
    )
  }
  
  export default SecondBlog
  //Third section
  