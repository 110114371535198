import BlogNavigation from "../blogNavigation"
import {RiArrowDropRightLine} from 'react-icons/ri'
import audit from '../BlogSection/audit.jpg'

const ThirdBlog = () => {
    return (
      <>
     <BlogNavigation/>
    <div className="bg-lightergray w-full h-28 flex gap-10 md:gap- lg:gap- xl:gap- 2xl:gap- justify-between xl:px-28 lg:px-44 md:px-44 px-4">
        <h1 className='font-professional text-black text-xs md:lg:text-xl xl:text-2xl 2xl:text-2xl font-semibold pt-10'>BOOKKEEPING?</h1>
        <div className='pt-12'>
        <h1 className='flex font-professional text-black text-xs md:lg:text-sm xl:text-sm 2xl:text-sm'>Blog<RiArrowDropRightLine className='h-5 w-5'></RiArrowDropRightLine>BOOKKEEPING</h1>
     </div>
     </div>

    <div className="bg-white w-full flex-wrap xl:flex-nowrap flex gap-5 p-4">
      <div className="w-full">
      <div className="w-full flex xl:flex-nowrap lg:flex-wrap 2xl:flex-nowrap flex-wrap gap-5">
      <div className="bg-[#fbf6f2] w-12/12 md:lg:w-full xl:w-8/12 2xl:w-8/12 rounded-2xl">
      <img src={audit} className="w-full rounded-3xl p-4"/>
        <h1 className="mx-4 mb-4 mt-4 text-black font-bold text-2xl font-standard">What Is BookKeeping?</h1>
        <h1 className="mx-4 text-black text-base p-0 md:p-0 lg:pb-4 xl:p-0 2xl:p-0">The financial management and documentation of every organization are very vital, regardless of its size or industry. Bookkeeping involves a systematic approach to recording, organizing, and tracking of not only financial transactions. Proper bookkeeping provides businesses with accurate financial information, enabling them to make informed decisions, meet legal obligations, and maintain a healthy financial position. 
</h1>
      </div>
      <div className="bg-[#edf4fb] w-12/12 md:w-4/12 lg:w-full xl:w-4/12 2xl:w-4/12 rounded-2xl">
      <img src={audit} className="w-full h-96 rounded-3xl p-4"/>
        <h1 className="mx-4 mb-4 mt-4 text-black font-bold text-2xl font-standard">Benefits of Proper Bookkeeping:</h1>
        <h1 className="mx-4 text-base mb-4">      1. Financial Lucidity and Informed Decision-making:
Proper bookkeeping ensures that accurate and up-to-date financial records are maintained. It managers and other users to monitor cash flow, identify trends, measure profitability, and determine the financial health of the business.They can strategize effectively, set realistic goals, and optimize operations with this knowledge.</h1>
<h1 className="mx-4 mb-4 text-base">
2. Legal and Tax Requirements:
Businesses are legally required to maintain accurate financial records for tax purposes. Proper bookkeeping ensures compliance with tax regulations and reduces the risk of penalties, fines, or audits.
</h1>
<h1 className="mx-4 mb-4 text-base">
3. Efficient Financial Analysis and Reporting:
The foundation for financial analysis and reporting is laid by effective bookkeeping. By organizing financial data into meaningful categories, businesses can generate reports such as profit and loss statements, balance sheets, and cash flow statements. 
</h1>
<h1 className="mx-4 mb-4 text-base">
4. Streamlined Budgeting and Forecasting:
Proper bookkeeping enables businesses to create accurate budgets and forecasts. By analyzing historical financial data, businesses can project future trends, anticipate expenses, and plan for growth
</h1>
      </div>
      </div>
      <div className="w-12/12 bg-[#edf4fb] mx-2 pb-2 flex xl:flex-nowrap lg:flex-nowrap 2xl:flex-nowrap flex-wrap mt-5 rounded-2xl">
      <img src={audit} className="w-12/12 md:w-6/12 lg:w-6/12 xl:w-6/12 2xl:w-6/12 rounded-3xl p-4"/>
      <div className="">
      <h1 className="mx-4 mb-4 mt-4 text-black font-bold text-2xl font-standard">Setbacks Resulting from Improper Bookkeeping:</h1>
      <h1 className="mx-4 text-base mb-4">        1. Inaccurate Financial Information:
Improper bookkeeping can lead to errors, o, or duplications in financial records. This can distort the true financial position of a business and misrepresent its profitability. </h1>
      <h1 className="mx-4 text-base mb-4">        2. Cash Flow Problems:
Without proper bookkeeping, it becomes challenging to track and manage cash flow effectively. Inadequate records can result in delayed payments, missed invoices, or inaccurate billing, leading to cash flow problems. </h1>
      <h1 className="mx-4 text-base mb-4">       3. Non-compliance and Legal Issues:
Improper bookkeeping increases the risk of non-compliance with tax and legal obligations. Incomplete or inaccurate financial records may result in incorrect tax filings, late payments, or failure to meet reporting requirements</h1>
      <h1 className="mx-4 text-base mb-4">        4. Missed Growth Opportunities:
Inadequate bookkeeping can hinder the identification of growth opportunities. Without accurate financial data, businesses may overlook potential areas of expansion, fail to capitalize on market trends, or misjudge their financial capabilities. This lack of insight can prevent businesses from making informed investments or securing financing for growth initiatives.</h1>
      </div>
      </div>
      </div>
    </div>

      </>
    )
  }
  
  export default ThirdBlog
  //Third section
  