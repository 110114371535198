import { useRef } from 'react'
import emailjs from '@emailjs/browser';


const Contact = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_yl701gz', 'template_25669bp', form.current, 'ysyyl7FZyNGMjAlij')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    };
    return (
        <>
            <section>
                <div className="bg-white">
                    <div className='flex flex-wrap justify-center md:px-44 lg:px-44 xl:px-44 py-20 md:gap-10 lg:gap-10 xl:gap-10'>
                        <div className=' w-4/12'>
                            <div className='flex justify-center w-full'>
                                <h1 className='text-2xl md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl font-standard text-black pt-4 pb-5 font-bold '>Contact us</h1>
                            </div>
                            <div ref={form} onSubmit={sendEmail} className='container'>
                                <div className='flex justify-center'>
                                    <div className='flex gap-8'>
                                        <div className='First-name'>
                                            <h1 className='text-base font-professional mb-2'>Full Name</h1>
                                            <input type="text" placeholder="" name="user_name" className='bg-white h-10 w-96 border border-gray rounded-lg' />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-center pt-4'>
                                    <div className='grouping'>
                                        <h1 className='text-base font-professional mb-2'>Email</h1>
                                        <input type="text" placeholder="" name="user_email" className='bg-white h-10 w-96 border border-gray rounded-lg' />
                                    </div>
                                </div>
                                <div className='flex justify-center pt-4'>
                                    <div className='phone number'>
                                        <h1 className='text-base font-professional mb-2'>Subject</h1>
                                        <input type="text" placeholder="" name="Subject" className='bg-white h-10 w-96 border border-gray rounded-lg' />
                                    </div>
                                </div>
                                <div className='flex justify-center pt-4 pb-4'>
                                    <div className='Message'>
                                        <h1 className='text-base font-professional mb-2'>Message</h1>
                                        <textarea name="" className='bg-white h-32 w-96 border border-gray rounded-lg'></textarea>
                                    </div>
                                </div>
                                <div className='flex justify-center'>
                                    <button type="submit" className='items-center my-4 bg-white w-96 h-10 rounded-xl text-black border border-black'>Contact Us</button>
                                </div>
                            </div>
                        </div>
                        <div className='mt-8 sm:mt-0 lg:mt-0 xl:mt-0'>
                            <div class="mapouter">
                                <div class="gmap_canvas">
                                    <iframe
                                        className='rounded-2xl'
                                        id="gmap_canvas"
                                        width="100%"
                                        height="400"
                                        src="https://maps.google.com/maps?q=++++++++++++++++PLOT+398%2C+MUHAMMADU+BUHARI+WAY+CENTRAL+BUSINESS+DISTRICT%2C+ABUJA.&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                        frameborder="0"
                                        scrolling="no"
                                        marginheight="0"
                                        marginwidth="0"
                                    ></iframe>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Contact
