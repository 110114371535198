import { Fragment } from 'react'
import Image from '../images/study.jpg'
import GeoImage from '../images/Geoimage1.jpg'
import Community from '../images/community.jpg'
import Initiative from '../images/initative.jpg'
import Research from '../images/researrch.jpg'
import { FaGlobeAfrica } from "react-icons/fa";


const Sectionone = () => {
   return (
      <>
         <div className="py-20">
            <div className=''>
               <h1 className='text-[#007e00] font-regular text-center font-sans text-2xl pt-8 pl-4 md:text-3xl lg:text-3xl xl:text-3xl'>Programs</h1>
               <h1 className='text-base md:text-base lg:text-base xl:text-xl  text-[#808080] text-center font-regular font-sans mt-4'>
                  Explore transformative Earth science programs for impactful learning in education, innovation, and sustainability.
               </h1>
            </div>
            <div className='w-12/12 flex flex-wrap md:flex-wrap lg:flex-wrap xl:flex-wrap 2xl:flex-nowrap justify-center gap-6 md:gap-6 lg:gap-6 xl:gap-20 2xl:gap-6 px-4 md:px-16 lg:px-16 xl:px-16 py-12'>
               <div className='w-12/12 md:w-80 lg:w-96 xl:w-96  rounded-lg shadow-xl'>
                  <img src={Research} className='w-12/12 h-96 rounded-t-lg' />
                  <div className='bg-white rounded-b-lg w-12/12 p-4'>
                     <h1 className='text-xl text-black font-bold font-sans text-center'>
                        Research Expeditions
                     </h1>
                     <h1 className='text-base text-[#808080] font-sans mt-2 text-center'>
                        Embark on a journey of scientific discovery with our Research Expeditions. These immersive experiences are carefully curated to offer a deep dive into Earth sciences ...
                        <span className='underline text-[#007e00]'>Read More</span>
                     </h1>
                  </div>
               </div>
               <div className='w-12/12 md:w-80 lg:w-96 xl:w-96  rounded-lg shadow-xl'>
                  <img src={Community} className='w-12/12 h-96 rounded-t-lg' />
                  <div className='bg-white rounded-b-lg w-12/12 p-4'>
                     <h1 className='text-xl text-black font-bold font-sans text-center'>
                        Community Engagement
                     </h1>
                     <h1 className='text-base text-[#808080] font-sans mt-2 text-center'>
                        Community Engagement involves a set of activities and programs aimed at connecting Earth sciences with communities, schools, and diverse demographics. It's about ...
                        <span className='underline text-[#007e00]'>Read More</span>
                     </h1>
                  </div>
               </div>
               <div className='w-12/12 md:w-80 lg:w-96 xl:w-96  rounded-lg shadow-xl'>
                  <img src={Initiative} className='w-12/12 h-96 rounded-t-lg' />
                  <div className='bg-white rounded-b-lg w-12/12 p-4'>
                     <h1 className='text-xl text-black font-bold font-sans text-center'>
                        Sustainability Initiatives
                     </h1>
                     <h1 className='text-base text-[#808080] font-sans mt-2 text-center'>
                        Sustainability Initiatives are at the core of our mission to make a positive impact on the environment. These initiatives encompass a series of projects and ...
                        <span className='underline text-[#007e00]'>Read More</span>
                     </h1>
                  </div>
               </div>
               <div className='w-12/12 md:w-80 lg:w-96 xl:w-96  rounded-lg shadow-xl'>
                  <img src={Image} className='w-12/12 h-96 rounded-t-lg' />
                  <div className='bg-white rounded-b-lg w-12/12 p-4'>
                     <h1 className='text-xl text-black font-bold font-sans text-center'>
                        Geology for Schools
                     </h1>
                     <h1 className='text-base text-[#808080] font-sans mt-2 text-center'>
                        "Geology for Schools" is a dynamic and interactive educational program that takes geology out of textbooks and into the hands and minds of students. It ...
                        <span className='underline text-[#007e00]'>Read More</span>
                     </h1>
                  </div>
               </div>
               <div className='w-12/12 md:w-80 lg:w-96 xl:w-96  rounded-lg shadow-xl'>
                  <img src={GeoImage} className='w-12/12 h-96 rounded-t-lg' />
                  <div className='bg-white rounded-b-lg w-12/12 p-4'>
                     <h1 className='text-xl text-black font-bold font-sans text-center'>
                        Earth Education Workshop
                     </h1>
                     <h1 className='text-base text-[#808080] font-sans mt-2 text-center'>
                        Join our immersive Earth Education Workshop and embark on a captivating journey through the geological wonders of our planet. This unique, hands-on experience ...
                        <span className='underline text-[#007e00]'>Read More</span>
                     </h1>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Sectionone
//SecondSection
