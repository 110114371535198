
import BlogNavigation from "../blogNavigation"
import {RiArrowDropRightLine} from 'react-icons/ri'
import audit from '../BlogSection/audit.jpg'
const FirstBlog = () => {
  return (
    <>
    <BlogNavigation/>
    <div className="bg-lightergray w-full h-28 flex gap-10 md:gap- lg:gap- xl:gap- 2xl:gap- justify-between xl:px-28 lg:px-44 md:px-44 px-4">
        <h1 className='font-professional text-black text-xs md:lg:text-xl xl:text-2xl 2xl:text-2xl font-semibold pt-10'>TAX ADMINISTRATION IN NIGERIA</h1>
        <div className='pt-12'>
        <h1 className='flex font-professional text-black text-xs md:lg:text-sm xl:text-sm 2xl:text-sm'>Blog<RiArrowDropRightLine className='h-5 w-5'></RiArrowDropRightLine>TAX ADMINISTRATION IN NIGERIA</h1>
     </div>
     </div>

    <div className="bg-white w-full flex-wrap xl:flex-nowrap flex gap-5 p-4">
      <div className="w-full">
      <div className="w-full flex xl:flex-nowrap lg:flex-nowrap 2xl:flex-nowrap flex-wrap gap-5">
      <div className="bg-[#fbf6f2] w-12/12 md:lg:w-8/12 xl:w-8/12 2xl:w-8/12 rounded-2xl">
      <img src={audit} className="w-full h-96 rounded-3xl p-4"/>
        <h1 className="mx-4 mb-4 mt-4 text-black font-bold text-2xl font-standard">Tax Administration in Nigeria</h1>
        <h1 className="mx-4 text- text-base">Tax administration is critical to any country's economic system, and Nigeria is no exception. The Nigerian tax system is governed by various laws and regulations that outline the types of taxes, tax rates, responsible collecting bodies, and procedures for tax compliance. 
</h1>
<h1 className="text-black font-bold text-2xl font-standard mx-4 mt-6">Tax Laws in Nigeria:</h1>
<h1 className="mx-4 text-base font- mb-4">Nigeria's primary legislation governing taxation is the Companies Income Tax Act (CITA) for corporate entities and the Personal Income Tax Act (PITA) for individuals. The Federal Inland Revenue Service (FIRS) enforces and implements these tax laws nationwide.
</h1>
      </div>
      <div className="bg-[#edf4fb] w-12/12 md:w-4/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12 rounded-2xl">
      <img src={audit} className="w-full h-96 rounded-3xl p-4"/>
        <h1 className="mx-4 mb-4 mt-4 text-black font-bold text-2xl font-standard">Responsible Collecting Bodies</h1>
        <h1 className="mx-4 text-base mb-4">    1. Federal Inland Revenue Service (FIRS):
The FIRS is the primary federal tax authority in Nigeria. It is responsible for administering and collecting federal taxes, including CIT, VAT, and WHT.</h1>
<h1 className="mx-4 mb-4 text-base">
2. State Internal Revenue Services (SIRS):
Each state in Nigeria has its own State Internal Revenue Service, responsible for the administration and collection of state taxes, primarily PIT.
</h1>
      </div>
      </div>
      <div className="w-12/12 bg-[#edf4fb] mx-2 pb-2 flex xl:flex-nowrap lg:flex-nowrap 2xl:flex-nowrap flex-wrap mt-5 rounded-2xl">
      <img src={audit} className="w-12/12 md:w-6/12 lg:w-6/12 xl:w-6/12 2xl:w-6/12 rounded-3xl p-4"/>
      <div className="">
      <h1 className="mx-4 mb-4 mt-4 text-black font-bold text-2xl font-standard">Tax Planning</h1>
      <h1 className="mx-4 text-base mb-4">    1. Compliance and Record Keeping:
Effective tax planning starts with proper compliance and record-keeping practices. To support their tax filings, individuals and businesses should maintain accurate financial records, including income, expenses, assets, and liabilities.</h1>
      <h1 className="mx-4 text-base mb-4">    2. Understanding Tax Incentives and Exemptions:
Tax planning involves identifying tax incentives and exemptions provided by the government. These incentives may include tax holidays, relief on specific expenses, or preferential tax rates for certain industries or regions.</h1>
      <h1 className="mx-4 text-base mb-4">    3. Timing of Income and Deductions:
Strategic timing of income recognition and deductions can optimize tax liabilities. For example, deferring income to a subsequent tax year or accelerating deductible expenses can result in reduced taxable income.</h1>
      <h1 className="mx-4 text-base mb-4">    4. Investment in Tax-Advantaged Schemes:
Exploring tax-advantaged investment schemes, such as pension contributions, can provide tax relief and long-term savings benefits.</h1>
      <h1 className="mx-4 text-base mb-4">    5. Seeking Professional Advice:
Tax professionals or consultants can help individuals and businesses navigate the complex tax landscape, ensuring compliance and maximizing tax benefits.</h1>
      </div>
      </div>
      </div>
      {/* D */}
      {/* Types of Taxes */}
     <div className="w-12/12 md:w-4/12 lg:w-full xl:w-4/12 2xl:w-4/12 bg-[#fbf6f2] rounded-2xl">
     <img src={audit} className="h-64 md:lg:xl:2xl: w-full rounded-3xl p-4"/>
      <h1 className="mx-4 mt-4 text-black font-bold text-2xl font-standard">Types of Taxes in Nigeria</h1>
      <h1 className="mx-4 text-base mt-4">
      1. Companies Income Tax (CIT):
CIT is imposed on registered companies' Taxable profit, including resident and non-resident companies operating in Nigeria. The current CIT rate is 20% for companies whose gross turnover is greater than or Equals 25 million Naira but less than 100 Million Naira, and 30% for companies whose gross turnover is greater than or equal to 100 Million Naira
      </h1>
      <h1 className="mx-4 text-base mt-4">
      2. Personal Income Tax (PIT):
PIT is levied on the income of individuals, including employees, self-employed individuals, and professionals. The tax is progressive, with rates ranging from 7.5% to 24% depending on income brackets. State IRS is responsible for the administration and collection of PIT.
      </h1>
      <h1 className="mx-4 text-base mt-4">
      3. Education Tax (EDT):
EDT is now governed by Tertiary Education Trust Fund (Establishment, Etc.) Act 2011 is imposed on all companies registered in Nigeria, and the rate of the tax is 3% of the assessable profit. The due date for filing returns is the same as that of CIT and PPT.
Funds derived from the tax are used for the rehabilitation, restoration, and consolidation of tertiary education in Nigeria by the Tertiary Education Trust Fund (TETFUND). The amount in the Fund is distributed between Universities, Polytechnics, and Colleges of Education in the ratio of 2:1:1 respectively
      </h1>
      <h1 className="mx-4 text-base mt-4">
      4. Value Added Tax (VAT):
VAT is a consumption tax charged on the supply of goods and services. The current VAT rate in Nigeria is 7.5%. It is collected by registered businesses on behalf of the government and remitted to the FIRS.
      </h1>
      <h1 className="mx-4 text-base mt-4">
      5. Withholding Tax (WHT):
WHT is an advance tax deducted from payments made to individuals and companies for specific types of income, such as dividends, interest, rent, and royalties. The rates for WHT vary depending on the nature of the income.
      </h1>

     </div>
      
    </div>

    </>
  )
}

export default FirstBlog
//Third section
