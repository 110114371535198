import { Routes, Route } from "react-router-dom";
import React from "react";
import About from "./components/about/about";
import Blog from "./components/blog/blogpage";
import ContactUs from "./components/contact/contact";
import Home from "./components/home/home";
import Ourservices from "./components/our_services/our-services";
import FirstBlog from "./components/blog/BlogSection/FirstBlog";
import SecondBlog from "./components/blog/BlogSection/SecondBlog";
import ThirdBlog from "./components/blog/BlogSection/ThirdBlog";





function App() {
  return (
<>
<Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/About-us' element={<About/>} />
    <Route path='/Services' element={<Ourservices/>}/>
    <Route path='/Blog' element={<Blog/>} />
    <Route path='/Contact-us' element={<ContactUs/>} />
    <Route path='/Tax-Administration-in-Nigeria' element={<FirstBlog/>} />
    <Route path='/Financial-Statements' element={<SecondBlog/>} />
    <Route path='/Bookkeeping' element={<ThirdBlog/>} />
</Routes>
    
</>
   

    );
}

export default App;
//App.js folder
