import { Fragment } from 'react'
import FooterSection from '../footer/footer'
import Navigation from '../navigation_bar/navigation_bar'
import BlogSection from './blog'
import BlogNavigation from './blogNavigation'
import BlogmainSection from './blogsections'



const Blog = () => {
  return (
    <>
    <BlogNavigation/>
    {/* <BlogSection/> */}
    <BlogmainSection/>
    <FooterSection/>
    </>
  )
}

export default Blog
//Home page
