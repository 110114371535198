import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaTwitter } from 'react-icons/fa';
import { BiNavigation } from 'react-icons/bi';
import { BsWhatsapp } from 'react-icons/bs';

const Sectiontwo = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_yl701gz', 'template_25669bp', form.current, 'ysyyl7FZyNGMjAlij')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  return (
    <div className='flex flex-wrap justify-center bg-white w-full'>
      <section className='w-full'>
        <div className='w-full'>
          <div className='flex flex-wrap justify-center w-full py-4 sm:py-8 md:py-10 lg:py-12 xl:py-16'>
            <div className='bg-white w-full sm:w-1/2 lg:w-1/3'>
              <h1 className='text-3xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-5xl font-standard text-black pt-4 pb-5 font-bold ml-4'>
                Get in touch
              </h1>
              <p className='text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl font-standard px-4'>
                We're here to help. Chat with our friendly team 24/7 and get set up and ready to go in just 5 minutes.
              </p>
              <div className='flex gap-3 p-4'>
                <BsLinkedin className='text-2xl' />
                <span>Connect with us</span>
              </div>
              <div className='flex gap-3 px-4'>
                <FaTwitter className='text-2xl' />
                <span>Message us on Twitter</span>
              </div>
              <div className='flex gap-3 p-4'>
                <BiNavigation className='text-2xl' />
                <span>Shoot us an email</span>
              </div>
              <div className='flex gap-3 px-4'>
                <BsWhatsapp className='text-2xl' />
                <span>Send us a message on WhatsApp</span>
              </div>
              <p className='p-4 py-8 font-standard'>
                PLOT 398, MUHAMMADU BUHARI WAY CENTRAL BUSINESS DISTRICT, ABUJA.
              </p>
            </div>
            <div className='mt-8 sm:mt-0 lg:mt-0 xl:mt-0'>
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    className='rounded-2xl'
                    id="gmap_canvas"
                    width="100%"
                    height="400"
                    src="https://maps.google.com/maps?q=++++++++++++++++PLOT+398%2C+MUHAMMADU+BUHARI+WAY+CENTRAL+BUSINESS+DISTRICT%2C+ABUJA.&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sectiontwo;
