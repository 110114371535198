import { Fragment } from 'react'
import { BiChevronRight } from "react-icons/bi"
import { BsTelephone } from "react-icons/bs"
import { BsBook } from "react-icons/bs";
import { IoBulbOutline } from "react-icons/io5";
import { FaGlobeAfrica } from "react-icons/fa";


const Hero = () => {
  return (
    <>
      <div className="bg-white">
        <div className='flex items-center'>
          <div className="bg-hero bg-cover py-24 flex items-center w-full">
            <div className="w-12/12 md:w-8/12 lg:w-8/12 xl:w-8/12 px-4 md:px-16 lg:px-16 xl:px-16">
              <h1 className='text-white font-bold font-sans pb-0 md:pb-2 lg:pb-2 xl:pb-2 flex  md: lg: xl: md:-20 lg:-40 xl:-40 sm:-4 text-2xl lg:text-3xl xl:text-4xl md:text-2xl'>Welcome to</h1>
              <h1 class='text-white font-bold font-sans pb-2 flex  md: lg: xl: md:-20 lg:-40 xl:-40 sm:-4 text-2xl lg:text-3xl xl:text-5xl md:text-3xl'>
                Grow Earth Science in Nigeria
              </h1>
              <h1 class='text-white font-regular font-sans pb-2 md: lg: xl: md:-20 lg:-40 xl:-40 sm:-4 text-lg lg:text-xl xl:text-2xl md:text-3xl'>
                Cultivating Knowledge, Nurturing Growth, and Embracing Earth Science in Nigeria—Igniting curiosity, fostering excellence, and paving pathways, we envision the Future of Earth Science in Nigeria as a beacon of transformative knowledge, innovation, and sustainable progress.
              </h1>
              <div className='pt-4 flex  md: lg: xl: mt-4'>
                <a href='/About-us'>
                  <button className='font-professional flex  gap-1 bg-white rounded-lg md:text-sm sm:text-xs text-black px-4 py-2'>Read More
                    <BiChevronRight className='text-xl text-black'></BiChevronRight>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap items-start w-full p-4 md:py-16 lg:p-16 xl:p-16 md:px-8 justify-between gap-8 md:gap-4 lg:gap-4 xl:gap-8 2xl:gap-8">
        <div className='bg-white w-12/12 md:w-4/12 lg:w-4/12 xl:w-4/12 pt-20 pb-10 md:py-0 lg:py-0 xl:py-0'>
          <h1 className='font-sans text-black font-bold text-3xl md:text-2xl lg:text-3xl xl:text-3xl 2xl:text-3xl pb-0 md:pb-0 lg:pb-4 xl:pb-0 2xl:pb-0'>Why Choose GESINA?</h1>
          <div className='py-2'>
            <h2 className='font-sans text-[#1a1a1a] text-base'>
              At GESINA, your journey into Earth science transcends ordinary exploration—here, you embark on an extraordinary odyssey defined by unparalleled expertise, innovative methodologies, and a resolute commitment to sustainable impact.
            </h2>
            <h2 className='font-sans font-bold text-xl mt-2'>Unrivaled Expertise:</h2>
            <div className='flex'>
              <li />
              <h2 className='font-sans font-regular text-base'>Engage with a diverse team of seasoned experts, spanning geologists, environmental scientists, educators, and industry professionals.</h2>
            </div>
            <div className='flex'>
              <li />
              <h2 className='font-sans font-regular text-base'>Benefit from a collective powerhouse that seamlessly blends academic rigor with practical, real-world experience.</h2>
            </div>
            {/*  */}
            <h2 className='font-sans font-bold text-xl mt-2'>Innovative Approaches:</h2>
            <div className='flex'>
              <li />
              <h2 className='font-sans font-regular text-base'>Experience the future of Earth science through GESINA's cutting-edge methodologies and technological advancements.</h2>
            </div>
            <div className='flex'>
              <li />
              <h2 className='font-sans font-regular text-base'>Explore a realm where innovation isn't just a buzzword but a constant pursuit, pushing the boundaries of traditional practices.</h2>
            </div>
            {/*  */}
          </div>
        </div>
        <div className='flex flex-wrap md:flex-nowrap lg:flex-wrap xl:flex-nowrap w-12/12 md:w-8/12 lg:w-8/12 xl:w-8/12 gap-4 md:gap-2 lg:gap-2 xl:gap-4 2xl:gap-4 pt-10 pb-20 md:py-0 lg:py-0 xl:py-0'>
          <div className='w-12/12 md:w-4/12 lg:w-72 xl:w-4/12 rounded-lg shadow-lg shadow-[#CCCCCC] pb-8'>
            <div className='w-12/12 justify-center'>
              <div className='w-12/12 flex justify-center mt-4'>
                <div className='border border-2 border-[#007e00] rounded-full flex justify-center'>
                  <BsBook className='text-center text-6xl text-[#007e00] p-4' />
                </div>
              </div>
              <h1 className='font-sans font-bold text-2xl p-4 text-center mt-2'>Unmatched Expertise</h1>
              <h1 className='font-sans font-regular text-base text-center px-6'>
                "Our team comprises seasoned experts and passionate individuals who bring a wealth of knowledge to the field of Earth science. From geologists and environmental scientists to educators and industry professionals, GESINA is a collective powerhouse of expertise. We take pride in our ability to blend academic rigor with real-world experience, ensuring a comprehensive and practical approach to Earth science."
              </h1>
            </div>
          </div>
          <div className='w-12/12 md:w-4/12 lg:w-72 xl:w-4/12 rounded-lg shadow-lg shadow-[#CCCCCC] pb-8'>
            <div className='w-12/12 justify-center'>
              <div className='w-12/12 flex justify-center mt-4'>
                <div className='border border-2 border-[#007e00] rounded-full flex justify-center'>
                  <IoBulbOutline className='text-center text-6xl text-[#007e00] p-4' />
                </div>
              </div>
              <h1 className='font-sans font-bold text-2xl p-4 text-center mt-2'>Innovative Approaches</h1>
              <h1 className='font-sans font-regular text-base text-center px-6'>
                "Embrace the future of Earth science with GESINA. We are at the forefront of adopting cutting-edge methodologies and technologies, pushing the boundaries of traditional practices. Our commitment to innovation is evident in every aspect of our work, from research initiatives to educational programs. By leveraging the latest advancements, we aim to redefine the landscape of Earth science and contribute to global scientific progress."
              </h1>
            </div>
          </div>
          <div className='w-12/12 md:w-4/12 lg:w-full xl:w-4/12 rounded-lg shadow-lg shadow-[#CCCCCC] pb-8'>
            <div className='w-12/12 justify-center'>
              <div className='w-12/12 flex justify-center mt-4'>
                <div className='border border-2 border-[#007e00] rounded-full flex justify-center'>
                  <FaGlobeAfrica className='text-center text-6xl text-[#007e00] p-4' />
                </div>
              </div>
              <h1 className='font-sans font-bold text-2xl p-4 text-center mt-2'>Sustainable Impact</h1>
              <h1 className='font-sans font-regular text-base text-center px-6'>
                "GESINA is committed to fostering a sustainable legacy for future generations. Our initiatives prioritize environmental stewardship and responsible resource management. By championing sustainable practices, we actively contribute to global goals. From advocating for eco-friendly policies to exploring renewable energy solutions, GESINA integrates innovative methodologies, ensuring that each project aligns with our dedication to a more sustainable and resilient future."
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero
//Main Hero section