import React from "react";
import FooterSection from "../footer/footer";
import Hero from "../hero/hero";
import Navigation from "../navigation_bar/navigation_bar";
import Sectionone from "../sections/section_one";
import Sectiontwo from "../sections/section_two";
import Section from "./Section";
import Section1 from "../about/about_us";
import Section2 from "../about/aboutsection";
import ServiceSection from "../our_services/our_services_section";
import BlogmainSection from "../blog/blogsections";
import ServiceSection1 from "../our_services/our_section";




function Home() {
  return (
    <>
      <Navigation />
      <Hero />
      <Section />
      <Sectionone />
      <Section2 />
      <ServiceSection />
      <ServiceSection1/>
      <BlogmainSection/>
      <Sectiontwo />
      <FooterSection />
    </>
  );
}

export default Home;
//App.js folder
