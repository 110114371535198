import { Fragment } from 'react'
import FooterSection from '../footer/footer'
import Navigation from '../navigation_bar/navigation_bar'
import ContactNavigation from './contactNavigation'
import Contact from './contactsection'
import ContactSection from './contact_us'
import ContactHero from '../hero/contactus'



const ContactUs = () => {
  return (
    <>
    <ContactNavigation/>
    <ContactHero/>
    {/* <ContactSection/> */}
    <Contact/>
    <FooterSection/>
    </>
  )
}

export default ContactUs
//Home page
