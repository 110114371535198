import { Fragment } from 'react'
import { BiChevronRight } from "react-icons/bi"
import { BsTelephone } from "react-icons/bs"

const ContactHero = () => {
    return (
        <div className="bg-white">
            <div className="flex items-center">
                <div className="bg-hero4 bg-cover py-20 sm:py-40 w-full">
                    <h1 className="text-white font-standard text-3xl sm:text-5xl font-semibold ml-4 md:ml-8 lg:ml-10 xl:ml-14 2xl:ml-40">
                        Contact us
                    </h1>
                    <h2 className="ml-4 sm:ml-40 text-white font-standard text-base sm:text-xl">
                        Feel free to get in touch with us; we'd love to hear from you.
                    </h2>
                </div>
            </div>
        </div>


    )
}
export default ContactHero
