import { Fragment } from 'react'
import { RiArrowDropRightLine } from 'react-icons/ri'
import Emmanuel from '../images/Babawale-Emmanuel-Salako.jpg'
import James from '../images/Olanipekun-Fabunmi-James.jpeg'
import Michael from '../images/Michael Owoseni.jpg'
import partner1 from '../images/Nipss.jpeg'
import partner2 from '../images/comeg.jpeg'
import partner3 from '../images/partner.jpeg'
import VAM from '../images/mission.jpg';
import MAV from '../images/vision.jpg'
import DGB from '../images/mission.jpg'
import SDG from '../images/SDG.jpg';

const ServiceSection = () => {
  return (
    <>
      <div className="p-4 md:p-16 lg:p-16 xl:p-16 2xl:p-16 w-full">
        <h1 className='text-black font-regular font-sans text-2xl pt-8 md:text-3xl lg:text-3xl xl:text-3xl mb-16 md:mb-8 lg:mb-8 xl:mb-8 2xl:mb-8 '>THE GEOSCIENCE SUSTAINABILITY CLOCK</h1>
        <div className='w-full flex flex-wrap justify-center gap-5 md:gap-10 lg:gap-10 xl:gap-10 2xl:gap-10'>
          <div className='w-full md:w-5/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12 py-0 md:py-0 lg:py-80 xl:py-80 2xl:py-0'>
            <img src={SDG} className='w-full rounded-3xl' />
          </div>
          <div className='w-12/12 md:w-5/12 lg:w-5/12 xl:w-5/12 flex-grow mt-4 md:mt-8 lg:mt-8 xl:mt-8 2xl:mt-8'>
            <p className='p-3 font-stamdard text-black text-base pt-2 md:text-base lg:text-base xl:text-base'>
              The Sustainability Clock is a symbolic representation of the interconnectedness of various aspects of sustainability and the time-sensitive nature of addressing global challenges. It's not a universally recognized symbol like a clock on a wall, but a conceptual framework used to depict the urgency and interdependence of different sustainable development goals and principles.
              <br></br>
              Here are some key elements that Healthics focuses on associated with the Sustainability Clock:
            </p>
            <p className='p-3 font-standard text-black text-base'>
              <span className='font-bold'>Quality Education (SDG 4)</span>
              Healthics's commitment to "Quality Education" is realized through its extensive Earth science education and awareness programs. We believe that educating individuals, particularly students and educators, in the field of geosciences is a fundamental step toward sustainability. By conducting workshops, seminars, and educational programs, Healthics ensures that the knowledge and expertise in Earth sciences are made accessible to a broader audience. We aim to empower individuals with the tools and understanding needed to address environmental challenges and contribute to the sustainable development of Nigeria and Africa.
            </p>
            <p className='p-3 font-standard text-black text-base'>
              <span className='font-bold'>Climate Action (SDG 13):</span>
              "Climate Action" is a core element of Healthics's sustainability initiatives. Earth sciences play a vital role in understanding climate change and its impacts. We actively advocate for responsible resource management, environmental conservation, and sustainable practices, emphasizing Earth sciences' contributions to sustainability. By engaging with the broader community and collaborating with other organizations, we aim to address climate change-related challenges effectively and work toward a more sustainable future for Nigeria and Africa.
            </p>
            <p className='p-3 font-standard text-black text-base'>
              <span className='font-bold'> Peace, Justice, and Strong Institutions (SDG 16):</span>
              Healthics recognizes the importance of "Peace, Justice, and Strong Institutions" in achieving sustainability. Geoscientists have a role to play in ensuring responsible practices in resource management and environmental protection. By advocating for responsible environmental stewardship and ethical resource management, we contribute to strong and just institutions. Our initiatives promote transparency, fairness, and accountability in the field of Earth sciences, aligning with SDG 16's objectives.
            </p>
            <p className='p-3 font-standard text-black text-base'>
              <span className='font-bold'>Partnerships for the Goals (SDG 17):</span>
              Healthics's approach to sustainability is highly collaborative, emphasizing the significance of "Partnerships for the Goals." We actively collaborate with universities, research institutions, governmental bodies, and other organizations. By working together, we aim to pool resources, share expertise, and create a more significant impact in addressing environmental challenges and promoting Earth sciences. Partnerships and collaborations are fundamental to our mission, contributing to the achievement of the Sustainable Development Goals in Nigeria and Africa.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceSection
//Third section
